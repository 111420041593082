import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Validation from "../../../Validation";
import { POST_API } from "../../../../Services/api";
import config from "../../../../Services/config.json";

function SocialEventSubmenu({ details }) {
  const [buttonDisabled, setButtonDisabled] = useState(false);
  const [errors, setErrors] = useState({});
  const [formResponseMessage, setFormResponseMessage] = useState("");
  const [enquiryForm, setEnquiryForm] = useState({
    name: "",
    phone_number: "",
    type: "",
    event_id: "",
  });

  useEffect(() => {
    setEnquiryForm({ ...enquiryForm, event_id: details?.id });
  }, [details]);

  const handleChange = (e) => {
    const { name, value } = e?.target;
    setEnquiryForm({ ...enquiryForm, [name]: value });
  };

  const handleEnquiryForm = async (e) => {
    e?.preventDefault();
    const isValid = Validation.validateEnquiryForm(enquiryForm);
    try {
      if (isValid && isValid?.error) {
        setButtonDisabled(false);
        setErrors({
          [isValid?.error?.details[0].path[0]]:
            isValid.error.details[0].message,
        });
        return;
      } else {
        setErrors({});
      }
      const addForm = await POST_API("addEnquiry", enquiryForm);
      if (addForm?.data?.status === 200) {
        setFormResponseMessage(addForm?.data?.message);
        setEnquiryForm({
          name: "",
          phone_number: "",
          type: "",
        });
      }
    } catch (error) {
      setFormResponseMessage("Failed to add details...!");
    }
  };

  return (
    <div className="body_content">
      <section
        className="breadcumb-section4 p-0"
        style={{
          backgroundImage: `url(${config?.path + details?.banner_image})`,
        }}
      >
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div className="breadcumb-style1">
                <h2 className="title text-white text-center">
                  {details?.title}
                </h2>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="pt60 pb90 bgc-f7">
        <div className="container">
          <div
            className="row wrap wow fadeInUp"
            data-wow-delay="500ms"
            style={{
              visibility: "visible",
              animationDelay: "500ms",
              animationName: "fadeInUp",
            }}
          >
            <div className="col-lg-8">
              <div className="ps-widget bgc-white bdrs12 default-box-shadow2 p30 mb30 overflow-hidden position-relative">
                <h4 className="title fz17 mb30">{details?.title}</h4>
                <p
                  style={{ fontSize: "12px", lineHeight: "2" }}
                  className="submenu-desc text mb10"
                  dangerouslySetInnerHTML={{
                    __html: details?.description,
                  }}
                ></p>
              </div>
            </div>
            <div className="col-lg-4">
              <div className="column" style={{ position: "relative" }}>
                <div
                  className="scrollbalance-inner"
                  style={{
                    width: "355.99px",
                    padding: "0px",
                    position: "absolute",
                    top: "0px",
                    left: "0px",
                  }}
                >
                  <div className="default-box-shadow1 bdrs12 bdr1 p30 mb30-md bgc-white position-relative">
                    <h6 className="title fz17 mb30">Get Enquiry</h6>

                    <form
                      className="form-style1"
                      onSubmit={(e) => {
                        handleEnquiryForm(e);
                      }}
                    >
                      <div className="row">
                        <div className="col-lg-12">
                          <div className="mb20">
                            <label className="heading-color ff-heading fw600 mb10">
                              Name
                            </label>
                            <input
                              type="text"
                              className="form-control"
                              placeholder="Name"
                              name="name"
                              value={enquiryForm?.name}
                              onChange={(e) => {
                                handleChange(e);
                              }}
                            />
                            {errors.name ? (
                              <div
                                className="validation-error-label"
                                style={{ marginTop: "4px" }}
                              >
                                {errors.name}
                              </div>
                            ) : null}
                          </div>
                        </div>
                        <div className="col-lg-12">
                          <div className="mb20">
                            <label className="heading-color ff-heading fw600 mb10">
                              Phone Number
                            </label>
                            <input
                              type="text"
                              className="form-control"
                              placeholder="Enter your Phone Number"
                              name="phone_number"
                              value={enquiryForm?.phone_number}
                              onChange={(e) => {
                                handleChange(e);
                              }}
                            />
                            {errors.phone_number ? (
                              <div
                                className="validation-error-label"
                                style={{ marginTop: "4px" }}
                              >
                                {errors.phone_number}
                              </div>
                            ) : null}
                          </div>
                        </div>

                        <div className="col-md-12">
                          <div className="mb20">
                            <label className="heading-color ff-heading fw600 mb10">
                              I am a
                            </label>
                            <select
                              className="form-control"
                              name="type"
                              value={enquiryForm?.value}
                              onChange={(e) => {
                                handleChange(e);
                              }}
                            >
                              <option>Select</option>
                              <option value="engineer">Engineer</option>
                              <option value="doctor">Doctor</option>
                              <option value="employee">Employee</option>
                              <option value="wedding_events_man">
                                Wedding Eventsman
                              </option>
                              <option value="other">Other</option>
                            </select>
                            {errors.type ? (
                              <div
                                className="validation-error-label"
                                style={{ marginTop: "4px" }}
                              >
                                {errors.type}
                              </div>
                            ) : null}
                          </div>
                        </div>

                        <div className="col-md-12">
                          <div className="d-grid">
                            <button
                              type="submit"
                              className="ud-btn btn-thm"
                              disabled={buttonDisabled}
                            >
                              Book Now
                              <i className="fal fa-arrow-right-long"></i>
                            </button>
                          </div>
                          <div
                            className="validation-error-label"
                            style={{ padding: "6px" }}
                          >
                            {formResponseMessage}
                          </div>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="our-pricing pb90 pt-30">
        <div className="container">
          <div
            className="row wow fadeInUp"
            data-wow-delay="100ms"
            style={{
              visibility: "visible",
              animationDelay: "100ms",
              animationName: "fadeInUp",
            }}
          >
            <div className="col-lg-6 offset-lg-3">
              <div className="main-title text-center mb30">
                <h2>Pricing Plan</h2>
              </div>
            </div>
          </div>

          <div
            className="row wow fadeInUp"
            data-wow-delay="300ms"
            style={{
              visibility: "visible",
              animationDelay: "300ms",
              animationName: "fadeInUp",
            }}
          >
            {details?.plan?.map((planList, i) => {
              return (
                <div className="col-md-6 col-xl-4" key={i}>
                  <div
                    className={`pricing_packages ${i === 0 ? "active" : ""}`}
                  >
                    <div className="heading mb60">
                      <h4 className="package_title">
                        {planList?.main_heading}
                      </h4>
                      <h1 className="text2" style={{ display: "block" }}>
                        {planList?.amount}
                      </h1>
                      <p className="text">{planList?.duration}</p>
                      <img
                        className="price-icon"
                        src="images/icon/pricing-icon-2.svg"
                        alt=""
                      />
                    </div>
                    <div className="details">
                      <p className="text mb35">{planList?.event_name}</p>
                      <div className="list-style1 mb40">
                        <p
                          style={{ fontSize: "12px", lineHeight: "2" }}
                          className="submenu-desc text mb10"
                          dangerouslySetInnerHTML={{
                            __html: planList?.description,
                          }}
                        ></p>
                      </div>
                      <div className="d-grid">
                        <Link
                          to={planList?.button_link}
                          className="ud-btn btn-thm-border text-thm"
                        >
                          {planList?.button}
                          <i className="fal fa-arrow-right-long"></i>
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </section>

      <section
        className="similar-product bgc-f8 pb90"
        style={{ padding: "unset" }}
      >
        <div className="container">
          <div className="row">
            <div className="col-lg-8">
              <div className="single-property-content mb30-md">
                <h2 className="sp-lg-title">Recent Photos</h2>
              </div>
            </div>
          </div>
          <div className="row ">
            {details?.images && (
              <>
                <div className="col-sm-6">
                  <div className="sp-img-content mb15-lg">
                    <div className="popup-img preview-img-2 sp-img mb10">
                      <img
                        className="w-100"
                        src={config?.path + details?.images[0]?.image_path}
                        alt="9.jpg"
                      />
                    </div>
                  </div>
                </div>

                <div className="col-sm-6">
                  <div className="row">
                    {details?.images?.slice(1).map((image, i) => {
                      return (
                        <div className="col-6 ps-sm-0" key={i}>
                          <div className="sp-img-content">
                            <div className="popup-img preview-img-2 sp-img mb10">
                              <img
                                className="w-100"
                                src={config?.path + image?.image_path}
                                alt={`${image?.image_path}.jpg`}
                              />
                            </div>
                          </div>
                        </div>
                      );
                    })}
                  </div>
                </div>
              </>
            )}
          </div>
        </div>
      </section>
    </div>
  );
}

export default SocialEventSubmenu;
