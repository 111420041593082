import React, { useEffect, useRef, useState } from "react";
import { Link } from "react-router-dom";
import { GET_API } from "../../Services/api";
import config from "../../Services/config.json";

function Contact() {
  const [footer, setFooter] = useState({});
  const loaderRef = useRef("");
  useEffect(() => {
    getContactDetails();
  }, []);

  const getContactDetails = async () => {
    const loader = loaderRef?.current;
    loader.className = "preloader";
    try {
      const contactDetails = await GET_API("contactus");
      if (contactDetails?.data?.status === 200) {
        setFooter(contactDetails?.data?.data);
        loaderRef.current.classList.remove("preloader");
      }
    } catch (error) {
      loaderRef.current.classList.remove("preloader");
    }
  };

  const contact = footer?.contact;

  return (
    <>
      <div ref={loaderRef}></div>
      <div className="body_content">
        <section
          className="breadcumb-section2 p-0"
          style={{
            backgroundImage: `url(${config.path}${contact?.banner_image})`,
          }}
        >
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <div className="breadcumb-style1">
                  <h2 className="title text-white text-center">Contact Us</h2>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="our-cta pt-0 pb-0">
          <div className="cta-banner bgc-f7 mx-auto maxw1600 pt120 pb120 pt60-md pb60-md bdrs12 position-relative mx20-lg">
            <div className="container">
              <div className="row align-items-center">
                <div className="col-lg-7 col-xl-6 wow fadeInLeft">
                  <div className="cta-style1">
                    <h2 className="cta-title">{contact?.title}</h2>
                    <p className="cta-text mb-0">{contact?.description}</p>
                  </div>
                </div>
                <div
                  className="col-lg-5 col-xl-6 wow fadeInRight"
                  data-wow-delay="300ms"
                >
                  <div className="cta-btns-style1 d-block d-sm-flex align-items-center justify-content-lg-end">
                    {/* <Link to="" className="ud-btn btn-transparent mr30 mr0-xs">
                      Contact Us<i className="fal fa-arrow-right-long"></i>
                    </Link> */}
                    <Link to="tel:+919539818364" className="ud-btn btn-dark">
                      <span className="flaticon-call vam pe-2"></span>
                      {contact?.mobile_number}
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </>
  );
}

export default Contact;
