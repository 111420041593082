import { BrowserRouter as Router, Routes, Route } from "react-router-dom";

import HomeLayout from "./Components/HomeLayout";
import Home from "./Components/Pages/Home";
import About from "./Components/Pages/About";
import ContactUs from "./Components/Pages/Contact";
import Gallery from "./Components/Pages/Gallery/Gallery";
import Portfolio from "./Components/Pages/Gallery/Portfolio";
import Blog from "./Components/Pages/Blog";
import VideoPlayer from "./Components/Layouts/VideoPlayer";
import EventCategory from "./Components/Pages/Events/index";
import Event from "./Components/Pages/Events/Event";
import { useEffect, useState } from "react";
import { GET_API } from "./Services/api";
import config from "./Services/config.json";
import SettingsContext from "./Components/API/SettingsProvider";

function App() {
  const [settingsDetails, setSettingsDetails] = useState({});
  useEffect(() => {
    getSettingInfo();
  }, []);

  const getSettingInfo = async () => {
    try {
      const details = await GET_API("sitesettings");
      if (details?.data?.status === 200) {
        document.title = details?.data?.data?.meta_title;
        setSettingsDetails(details?.data?.data);
        updateFavicon(details?.data?.data?.favicon);
        updateMetaDesc(details?.data?.data);
        updateMetaKeyword(details?.data?.data);
      }
    } catch (error) {
      console.log("Failed to get settings info");
    }
  };

  const updateFavicon = (faviconUrl) => {
    const existingFavicon = document.querySelector('link[rel="icon"]');
    if (existingFavicon) {
      document.head.removeChild(existingFavicon);
    }
    const newFavicon = document.createElement("link");
    newFavicon.rel = "icon";
    newFavicon.href = config.path + faviconUrl;
    document.head.appendChild(newFavicon);
  };

  const updateMetaDesc = (data) => {
    const existingMetaDesc = document.querySelector(`meta[name="description"]`);
    if (existingMetaDesc) {
      existingMetaDesc.content = data?.meta_description;
    } else {
      const newMetaDesc = document.createElement("meta");
      newMetaDesc.content = data?.meta_description;
      document.head.appendChild(newMetaDesc);
    }
  };

  const updateMetaKeyword = (data) => {
    const existingMetaKeyword = document.querySelector(`meta[name="keywords"]`);
    if (existingMetaKeyword && existingMetaKeyword !== null) {
      existingMetaKeyword.content = data?.meta_keyword;
    } else {
      const newMetaKeyword = document.createElement("meta");
      newMetaKeyword?.setAttribute("name", "keywords");
      newMetaKeyword.content = data?.meta_keyword;
      document.head.appendChild(newMetaKeyword);
    }
  };

  return (
    <SettingsContext.Provider value={settingsDetails}>
      <Router>
        <Routes>
          <Route path="/" element={<HomeLayout />}>
            <Route path="/" element={<Home />}></Route>
            <Route path="/reel" element={<VideoPlayer />}></Route>
            <Route path="/contact" element={<ContactUs />}></Route>
            <Route path="/about" element={<About />}></Route>
            <Route path="/gallery" element={<Gallery />}></Route>
            <Route
              path="/gallery/portfolioview/:id"
              element={<Portfolio />}
            ></Route>
            <Route path="/blog" element={<Blog />}></Route>
            {/* Event */}
            <Route
              path="/eventCategory/:slug"
              element={<EventCategory />}
            ></Route>
            <Route
              path="/eventCategory/:slug/:submenu"
              element={<Event />}
            ></Route>
          </Route>
        </Routes>
      </Router>
    </SettingsContext.Provider>
  );
}

export default App;
