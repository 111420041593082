import Layout from "./Layouts/Layouts";
import { Outlet } from "react-router-dom";

function AllRoutes() {
  return (
    <Layout>
      <Outlet />
    </Layout>
  );
}

export default AllRoutes;
